import React from 'react';

import { Link, Grid, Typography, ThemeProvider, makeStyles, createTheme } from "@material-ui/core"
// import { graphql, useStaticQuery } from "gatsby"

export default function Home() {
  const theme = createTheme();
  theme.typography.h3 = {
    fontSize: '1.2rem',
    '@media (min-width:600px)': {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '2.4rem',
    },
  };

  const useStyles = makeStyles((theme) => ({
    logo: {
      [theme.breakpoints.up('sm')]: {
        width: 400,
      },
      width: '100%'
    },
    name: {
      fontFamily: "Roboto Mono"
    }
  })
  )
  const classes = useStyles();
  //
  //
  // const query = graphql`
  //     {
  //         website(id: {eq: "web"}) {
  //             copy
  //         }
  //     }
  // `
  // const siteData = useStaticQuery(query)

  return (
    <ThemeProvider theme={theme}>
    <Grid container direction="column" alignContent="center" alignItems="center">
      <Grid item>
        <img src={'img/logo.png'} className={classes.logo} alt="logo"/>
      </Grid>
      <Grid item >
        <Typography variant={"h6"} align="center">
          <Link href="https://en.wikipedia.org/wiki/Thesis,_antithesis,_synthesis" target="_blank" >Thesis, Antithesis, Synthesis</Link>
        </Typography>
        <Typography variant={"h6"} align="center">F.A.Y.A.M.F.</Typography>


      </Grid>
      <Grid item   container
            direction="column"
            justifyContent="center"
            alignItems="center"
      >
        <Grid item style={{alignSelf : 'center'}}><pre>&copy;&nbsp;2021 Thansys Technologies, LLC</pre></Grid>
        <Grid item style={{alignSelf : 'center'}}><pre>"I would prefer not to."</pre></Grid>
      </Grid>
    </Grid>

    </ThemeProvider>
)
}

